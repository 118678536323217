import { createPromiseClient } from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
import { AdminConsoleService } from "schema/gen/es/foodlosscoupon/adminconsole/v1/adminconsole_connect";
import { withTQueryHelpers } from "./queryUtils";
import { Message, PlainMessage } from "@bufbuild/protobuf";

const transport = createConnectTransport({
  baseUrl: location.origin,
  useBinaryFormat: import.meta.env.PROD,
  useHttpGet: true,
  interceptors: [
    (next) => async (req) => {
      req.header.set(
        "Authorization",
        `Bearer ${await window.Clerk?.session?.getToken()}`
      );
      return await next(req);
    },
  ],
});

/**
 * persistQueryClientでIDBに保存するときにメソッドなどは消えてしまうので、
 * 予めここでメソッドの部分は型から除いておく
 */
const castResponseAsPlain = <T>(client: T) =>
  client as CastResponseAsPlainObject<T>;
type CastResponseAsPlainObject<T> = {
  [K in keyof T]: CastResponseAsPlain<T[K]>;
};
type CastResponseAsPlain<T> = T extends (
  ...args: infer Args
) => Promise<infer Return extends Message<Return>>
  ? ((...args: Args) => Promise<PlainMessage<Return>>) & {
      [K in keyof T]: T[K];
    }
  : T;

export const api = castResponseAsPlain(
  withTQueryHelpers(
    AdminConsoleService.typeName,
    createPromiseClient(AdminConsoleService, transport)
  )
);

// export const fetchData = async (clerkUserId: string) => {
//   const transport = createConnectTransport({
//     baseUrl: "/", // gRPC サーバーのエンドポイント
//   });

//   const client = createPromiseClient(AdminConsoleService, transport);

//   try {
//     const response = await client.getTenantStaffByClerkID({
//       clerkUserId: clerkUserId,
//     });
//     return response;
//   } catch (error) {
//     console.error("Error fetching example:", error);
//   }
// };
