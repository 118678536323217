import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();

  // パスを分割して最後のセグメントを取得
  const lastPathSegment =
    location.pathname.split("/").filter(Boolean).pop() || "";

  return (
    <>
      <nav className="fixed w-[188px] h-screen p-[16px] border-[1px] border-r-lightGray border-r-solid">
        <div>
          <p className="mt-0 mb-[5px] text-[12px] text-secondary font-hiragino">
            ショップ
          </p>
          <ul className="m-0 px-0">
            <Link
              to="/"
              className={`block flex gap-[6px] w-[172px] h-[24px] p-[8px] text-[14px] leading-6 no-underline rounded-lg font-hiragino hover:bg-#001EFB hover:bg-opacity-[0.05] ${lastPathSegment === "" ? "font-semibold text-#001EFB bg-accentPrimaryLight" : "text-secondary"}`}
            >
              <div>
                <span className="material-symbols-rounded text-[24px]">
                  storefront
                </span>
              </div>
              ショップ一覧
            </Link>
          </ul>
        </div>
        <div className="mt-[24px]">
          <p className="mt-0 mb-[5px] text-[12px] text-secondary font-hiragino">
            設定
          </p>
          <ul className="m-0 px-0">
            <Link
              to="/staffs"
              className={`block flex gap-[6px] w-[172px] h-[24px] p-[8px] text-[14px] leading-6 no-underline rounded-lg font-hiragino hover:bg-#001EFB hover:bg-opacity-[0.05] ${lastPathSegment === "staffs" ? "font-semibold text-#001EFB bg-accentPrimaryLight" : "text-secondary"}`}
            >
              <div>
                <span className="material-symbols-rounded text-[24px]">
                  person
                </span>
              </div>
              スタッフ管理
            </Link>
          </ul>
          <ul className="m-0 px-0">
            <Link
              to="/app/setting"
              className={`block flex gap-[6px] w-[172px] h-[24px] p-[8px] text-[14px] leading-6 no-underline rounded-lg font-hiragino hover:bg-#001EFB hover:bg-opacity-[0.05] ${lastPathSegment === "setting" ? "font-semibold text-#001EFB bg-accentPrimaryLight" : "text-secondary"}`}
            >
              <div>
                <img src="/l2_home.svg" alt="l2_home" />
              </div>
              アプリ設定
            </Link>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Sidebar;
