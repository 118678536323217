const week = ["日", "月", "火", "水", "木", "金", "土"];
export const formatDate = (date: Date, format: string) => {
  format = format.replace(/yyyy/g, String(date.getFullYear()));
  format = format.replace(/MM/g, ("0" + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/dd/g, ("0" + date.getDate()).slice(-2));
  format = format.replace(/ww/g, week[date.getDay()]);
  format = format.replace(/HH/g, ("0" + date.getHours()).slice(-2));
  format = format.replace(/mm/g, ("0" + date.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ("0" + date.getSeconds()).slice(-2));
  format = format.replace(/H/g, String(date.getHours()));
  format = format.replace(/m/g, String(date.getMinutes()));
  format = format.replace(/s/g, String(date.getSeconds()));
  return format;
};
