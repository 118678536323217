import { useState, useEffect, useRef } from "react";
import "dayjs/locale/ja";
import { Timestamp, PlainMessage } from "@bufbuild/protobuf";
import {
  useMutationAddShop,
  useMutationCreateShopLoginIDAndHashedPassword,
  useMutationEditShopName,
  useQueryGetShopsByTenantID,
} from "../../hooks/useQuery";
import { formatDate } from "../../utils/date";
import {
  Shop,
  CreateShopLoginIDAndHashedPasswordResponse,
} from "schema/gen/es/foodlosscoupon/adminconsole/v1/adminconsole_pb";
import Toast from "../molecules/toast";
import psImg from "../../assets/ps.png";
import html2canvas from "html2canvas";
import { getQRURL } from "../../utils/qrcode";
import { useQRCode } from "../../hooks/useQrcode";
import { useOutletContext } from "react-router-dom";
import { OutletContext } from "../templates/protectedLayout";

function ShopListPage() {
  const { serviceID } = useOutletContext<OutletContext>();
  const [shopName, setShopName] = useState("");
  const [selectedShop, setSelectedShop] = useState<PlainMessage<Shop> | null>(
    null
  );
  const [isMenuOpen, setIsMenuOpen] = useState<number | null>(null);
  const [menuPosition, setMenuPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 }); // ポップアップの位置
  const [currentPage, setCurrentPage] = useState(1); // 現在のページ番号
  const limit = 20; // 1ページに表示する件数
  const { data: getShopsByTenantID, refetch } = useQueryGetShopsByTenantID(
    currentPage,
    limit
  );
  const [IsAddShopModalOpen, setIsAddShopModalOpen] = useState(false);
  const [
    isCreateShopLoginIDAndHashedPasswordModalOpen,
    setIsCreateShopLoginIDAndHashedPasswordModalOpen,
  ] = useState(false);
  const [
    isFirstCreateShopLoginIDAndHashedPassword,
    setIisFirstCreateShopLoginIDAndHashedPassword,
  ] = useState(false);
  const [
    IsShopIDAndPasswordConfirmModalOpen,
    setShopIDAndPasswordConfirmModalOpen,
  ] = useState(false);
  const [isEditShopModalOpen, setIsEditShopModalOpen] = useState(false);
  const [isQLCodeModalOpen, setIsQLCodeModalOpen] = useState(false);
  const [createShopIDAndPasswordResponse, setCreateShopIDAndPasswordResponse] =
    useState<PlainMessage<CreateShopLoginIDAndHashedPasswordResponse> | null>(
      null
    );
  const addShopMutation = useMutationAddShop();
  const editShopNameMutation = useMutationEditShopName();
  const createShopLoginIDAndHashedPasswordMutation =
    useMutationCreateShopLoginIDAndHashedPassword();
  const buttonRefs = useRef<(HTMLDivElement | null)[]>([]);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const { Canvas } = useQRCode();
  const canvasRef = useRef<HTMLDivElement>(null);
  const [isDownloadMode, setIsDownloadMode] = useState(false);
  const [shopNameError, setShopNameError] = useState(false);
  const [shopNameErrorMessage, setShopNameErrorMessage] = useState("");

  const handleShopNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShopName(event.target.value);
    // フォームの内容が変わったらエラー表示をリセット
    if (shopNameError) {
      setShopNameError(false);
      setShopNameErrorMessage("");
    }
  };
  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLElement).id === "modal-overlay") {
      resetState();
      setIsAddShopModalOpen(false);
      setIsCreateShopLoginIDAndHashedPasswordModalOpen(false);
      setIsEditShopModalOpen(false);
      setIsQLCodeModalOpen(false);
    }
  };

  const resetState = () => {
    setShopName("");
    setSelectedShop(null);
    setCreateShopIDAndPasswordResponse(null);
    setShopNameError(false);
    setShopNameErrorMessage("");
  };

  const toggleMenu = (index: number) => {
    if (buttonRefs.current[index]) {
      const rect = buttonRefs.current[index]?.getBoundingClientRect();
      setMenuPosition({
        top: (rect?.bottom ?? 0) + window.scrollY, // スクロール位置を加味
        left: (rect?.left ?? 0) + window.scrollX, // スクロール位置を加味
      });
    }
    setIsMenuOpen((prev) => (prev === index ? null : index)); // クリックされた行のメニューを開く・閉じる
  };

  const isOkButtonDisabled = () => {
    return shopName == "";
  };

  const OpenCreateShopIDAndPasswordModal = (shop: PlainMessage<Shop>) => {
    setSelectedShop(shop);
    if (shop.loginId) {
      setIisFirstCreateShopLoginIDAndHashedPassword(false);
    } else {
      setIisFirstCreateShopLoginIDAndHashedPassword(true);
    }
    setIsCreateShopLoginIDAndHashedPasswordModalOpen(true);
  };

  const OpenQLCodeModal = (shop: PlainMessage<Shop>) => {
    setSelectedShop(shop);
    setIsQLCodeModalOpen(true);
  };

  const OpenEditShopModal = (shop: PlainMessage<Shop>) => {
    setSelectedShop(shop);
    setShopName(shop.name);
    setIsEditShopModalOpen(true);
  };

  const handleAddShopSubmit = async () => {
    try {
      await addShopMutation.mutateAsync({ shopName });
      setIsAddShopModalOpen(false);
      setToastMessage("ショップを追加しました");
      setShowToast(true);
      resetState();
    } catch (error) {
      setShopNameError(true);
      setShopNameErrorMessage("すでに同じショップ名が存在します。");
    }
  };

  const handleEditShopName = async () => {
    try {
      await editShopNameMutation
        .mutateAsync({
          shopId: selectedShop?.shopId,
          shopName: shopName,
        })
        .then(() => {
          setIsEditShopModalOpen(false);
          setToastMessage("ショップを更新しました");
          setShowToast(true);
          resetState();
        });
    } catch (error) {
      setShopNameError(true);
      setShopNameErrorMessage("すでに同じショップ名が存在します。");
    }
  };

  const handleCreateShopIDAndPasswordSubmit = () => {
    createShopLoginIDAndHashedPasswordMutation
      .mutateAsync({
        shopId: selectedShop?.shopId,
      })
      .then(
        (
          response: PlainMessage<CreateShopLoginIDAndHashedPasswordResponse>
        ) => {
          setCreateShopIDAndPasswordResponse(response);
          setIsCreateShopLoginIDAndHashedPasswordModalOpen(false);
          setShopIDAndPasswordConfirmModalOpen(true);
        }
      );
  };

  const nextPage = () => {
    if (getShopsByTenantID && getShopsByTenantID.total > currentPage * limit) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  // ページを戻す処理
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleDownloadPNG = async () => {
    setIsDownloadMode(true);
    await new Promise((resolve) => setTimeout(resolve, 100));

    if (canvasRef.current) {
      const canvasImage = await html2canvas(canvasRef.current, {
        useCORS: true,
        scale: 2, // 高解像度
        logging: true,
      });

      const imgData = canvasImage.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = imgData;
      link.download = `${selectedShop?.name || "QR_Code"}_code.png`;
      link.click();
    }

    setIsDownloadMode(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        !buttonRefs.current.some((ref) => ref?.contains(event.target as Node))
      ) {
        setIsMenuOpen(null); // ポップアップを非表示に
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    refetch(); // currentPageやlimitが変わったらデータを再取得する
  }, [currentPage, limit, refetch]);

  return (
    <>
      <div className="p-[24px]">
        <div className="flex justify-between">
          <h2 className="m-0 text-[20px] font-semibold leading-10 font-hiragino">
            ショップ一覧
          </h2>
          <button
            type="button"
            onClick={() => setIsAddShopModalOpen(true)}
            className="block flex gap-[2px] w-[182px] h-[40px] py-[8px] pl-[20px] pr-[24px] rounded-[12px] bg-#001EFB text-[16px] font-medium font-hiragino border-0  text-white  hover:bg-#334BFC hover:cursor-pointer focus:outline-none"
          >
            <div>
              <span className="material-symbols-rounded  text-[24px]">add</span>
            </div>
            <div className="leading-5.8">ショップを追加</div>
          </button>
        </div>
        <div className="mt-[24px] bg-white rounded-t-[16px]">
          <div className="flex justify-end h-[54px] px-[24px] border-b-[1px] border-b-lightGray border-b-solid">
            <div className="flex gap-[16px] w-[130px]">
              <p className="w-[62px] my-0 text-[#111827] text-[12px] leading-14">
                {currentPage}/
                {Math.ceil((getShopsByTenantID?.total || 1) / limit)}ページ
              </p>
              <div className="flex gap-[4px] pt-[15px]">
                <div onClick={prevPage}>
                  <span className="material-symbols-rounded text-[24px] rounded-999 hover:cursor-pointer">
                    arrow_back
                  </span>
                </div>
                <div onClick={nextPage}>
                  <span className="material-symbols-rounded text-[24px] rounded-999 hover:cursor-pointer">
                    arrow_forward
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex h-[48px] px-[24px] gap-[24px]">
            <p className="w-[200px] text-[14px] font-hiragino text-secondary">
              ショップ名
            </p>
            <p className="w-[200px] text-[14px] font-hiragino text-secondary">
              登録日時
            </p>
            <div className="w-8"></div>
          </div>
        </div>
        <div className="bg-white mt-4px rounded-b-[16px]">
          {getShopsByTenantID?.shops.map((shop, index) => {
            return (
              <div
                key={index}
                className={`flex h-[64px] px-[24px] gap-[24px] ${index === getShopsByTenantID?.shops.length - 1 ? "rounded-b-[16px]" : ""} border-b-[1px] border-b-lightGray border-b-solid`}
              >
                <p className="w-[200px] whitespace-nowrap overflow-hidden text-ellipsis text-[14px] leading-10 font-hiragino text-#111827">
                  {shop.name}
                </p>
                <p className="w-[200px] whitespace-nowrap overflow-hidden text-ellipsis text-[14px] leading-10 font-hiragino text-#111827">
                  {formatDate(
                    (shop?.createdAt as Timestamp)?.toDate() ?? new Date(0),
                    "yyyy/MM/dd(ww) H:mm"
                  )}
                </p>
                <div
                  onClick={() => toggleMenu(index)}
                  ref={(el) => (buttonRefs.current[index] = el)} // ボタンの参照を設定
                  className="w-[24px] p-[4px] ml-auto "
                >
                  <span className="material-symbols-rounded text-[24px] mt-[15px] cursor-pointer rounded-[9999px]">
                    more_vert
                  </span>
                </div>
                {isMenuOpen === index && (
                  <div
                    ref={menuRef}
                    style={{
                      position: "absolute",
                      top: `${menuPosition.top}px`,
                      left: `${menuPosition.left - 145}px`,
                    }}
                    className="z-10 w-[182px] p-[4px] bg-white rounded-[8px] border-[1px] border-solid border-lightGray shadow-DropShadowLow"
                  >
                    <ul className="m-0 p-0">
                      <li
                        onClick={() => OpenCreateShopIDAndPasswordModal(shop)}
                        className="flex gap-[2px] w-[162px] h-[16px] px-[10px] py-[8px] rounded-[4px] text-[14px] list-none hover:bg-gray-100 cursor-pointer"
                      >
                        <div>
                          <span className="material-symbols-rounded text-[20px]">
                            key_vertical
                          </span>
                        </div>
                        <div className="w-[140px] h-[20px] leading-5">
                          IDとパスワード生成
                        </div>
                      </li>
                      <li
                        onClick={() => OpenQLCodeModal(shop)}
                        className="flex gap-[2px] w-[162px] h-[16px] px-[10px] py-[8px] rounded-[4px] text-[14px] list-none hover:bg-gray-100 cursor-pointer"
                      >
                        <div>
                          <span className="material-symbols-rounded text-[20px]">
                            qr_code
                          </span>
                        </div>
                        <div className="w-[140px] h-[20px] leading-5">
                          QRコードを表示
                        </div>
                      </li>
                      <li
                        onClick={() => OpenEditShopModal(shop)}
                        className="flex gap-[2px] w-[162px] h-[16px] px-[10px] py-[8px] rounded-[4px] text-[14px] list-none hover:bg-gray-100 cursor-pointer"
                      >
                        <div>
                          <span className="material-symbols-rounded text-[20px]">
                            edit
                          </span>
                        </div>
                        <div className="w-[140px] h-[20px] leading-5">編集</div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      {IsAddShopModalOpen && (
        <div
          id="modal-overlay"
          tabIndex={-1}
          onClick={handleOverlayClick}
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm bg-surfaceOverlay"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative w-[492px] h-[188px]"
          >
            <div className="relative flex flex-col items-start gap-[20px] w-[492px] h-[188px] p-[24px] bg-white border-[1px] border-lightGray border-solid rounded-[16px] shadow-DropShadowLow">
              <h3 className="my-0 text-[18px] font-medium text-[#111827] font-hiragino">
                ショップを追加
              </h3>
              <div className="flex flex-col items-start w-full gap-[4px]">
                <div className="flex gap-[8px]">
                  <p className="my-0 text-[16px] text-[#111827] font-hiragino">
                    ショップ名
                  </p>
                  <p className="my-0 text-[14px] text-[#B91C1C] leading-5 font-hiragino">
                    必須
                  </p>
                </div>
                <input
                  className={`w-[460px] h-[16px] appearance-none border-[1px] ${
                    shopNameError ? "border-[#B91C1C]" : "border-lightGray"
                  } border-solid rounded-[12px] py-[16px] pl-[18px] pr-[14px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-tertiary`}
                  id="couponName"
                  type="text"
                  placeholder="ショップ名を入力してください"
                  value={shopName}
                  onChange={handleShopNameChange}
                />
                {shopNameError && (
                  <p className="my-0 text-[14px] text-[#B91C1C] leading-5 font-hiragino">
                    {shopNameErrorMessage}
                  </p>
                )}
              </div>
              <div className="flex justify-end w-[492px] h-[48px] gap-[12px]">
                <button
                  type="button"
                  className="block w-[136px] h-[48px] px-[28px] py-[12px] text-[16px] rounded-[12px] font-medium font-hiragino border-none focus:outline-none bg-white  hover:bg-gray-100 hover:cursor-pointer focus:z-10"
                  onClick={() => {
                    setIsAddShopModalOpen(false);
                    resetState();
                  }}
                >
                  キャンセル
                </button>
                <button
                  type="button"
                  className={`block  w-[128px] h-[48px] py-[12px] px-[32px] text-[16px] font-medium font-hiragino border-none rounded-[12px] ${
                    isOkButtonDisabled()
                      ? "bg-[#E4E4E7] cursor-not-allowed text-disableText" // 非活性時のスタイル
                      : "bg-[#001EFB] text-white hover:bg-#334BFC focus:ring-blue-300 hover:cursor-pointer" // 通常時のスタイル
                  }`}
                  onClick={() => {
                    handleAddShopSubmit();
                  }}
                  disabled={isOkButtonDisabled()}
                >
                  追加する
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isCreateShopLoginIDAndHashedPasswordModalOpen && (
        <div
          id="modal-overlay"
          tabIndex={-1}
          onClick={handleOverlayClick}
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm bg-surfaceOverlay"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className={`relative w-[492px] ${isFirstCreateShopLoginIDAndHashedPassword ? "h-[136px]" : "156px"}`}
          >
            <div
              className={`relative flex flex-col items-start gap-[20px] w-[492px] ${isFirstCreateShopLoginIDAndHashedPassword ? "h-[136px]" : "156px"} p-[24px] bg-white border-[1px] border-lightGray border-solid rounded-[16px] shadow-DropShadowLow`}
            >
              <div>
                <h3 className="my-0 text-[18px] font-medium text-[#111827] font-hiragino">
                  {isFirstCreateShopLoginIDAndHashedPassword
                    ? "IDとパスワードを生成しますか？"
                    : "IDとパスワードを再生成しますか？"}
                </h3>
                <p
                  className={`m-0 mt-2 text-[14px] ${isFirstCreateShopLoginIDAndHashedPassword ? "text-secondary " : "text-#B91C1C"}  font-hiragino leading-5`}
                >
                  {isFirstCreateShopLoginIDAndHashedPassword
                    ? "ログイン用のIDとパスワードは一度しか表示されませんので必ずメモをお取りください"
                    : "すでにIDとパスワードを生成済みです。この場合に再生成すると、新規のIDとパスワードが発行され、今までご利用いただいていたIDとパスワードはご利用いただけなくなりますのでご注意ください。"}
                </p>
              </div>
              <div className="flex justify-end w-[492px] h-[48px] gap-[12px]">
                <button
                  type="button"
                  className="block w-[136px] h-[48px] px-[28px] py-[12px] text-[16px] rounded-[12px] font-semibold font-hiragino border-none focus:outline-none bg-white  hover:bg-gray-100 hover:cursor-pointer focus:z-10"
                  onClick={() => {
                    setIsCreateShopLoginIDAndHashedPasswordModalOpen(false);
                  }}
                >
                  キャンセル
                </button>
                <button
                  type="button"
                  className={`block ${isFirstCreateShopLoginIDAndHashedPassword ? "w-[120px]" : "w-[132px]"}  h-[48px] py-[12px] px-[24px] text-[16px] font-semibold font-hiragino border-none rounded-[12px] bg-[#001EFB] text-white hover:bg-#334BFC hover:cursor-pointer focus:ring-blue-300`}
                  onClick={() => {
                    handleCreateShopIDAndPasswordSubmit();
                  }}
                >
                  {isFirstCreateShopLoginIDAndHashedPassword
                    ? "生成する"
                    : "再生成する"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isEditShopModalOpen && (
        <div
          id="modal-overlay"
          tabIndex={-1}
          onClick={handleOverlayClick}
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm bg-surfaceOverlay"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative w-[492px] h-[188px]"
          >
            <div className="relative flex flex-col items-start gap-[20px] w-[492px] h-[188px] p-[24px] bg-white border-[1px] border-lightGray border-solid rounded-[16px] shadow-DropShadowLow">
              <h3 className="my-0 text-[18px] font-medium text-[#111827] font-hiragino">
                ショップを編集
              </h3>
              <div className="flex flex-col items-start w-full gap-[4px]">
                <div className="flex gap-[4px]">
                  <p className="my-0 text-[16px] text-[#111827] font-hiragino">
                    ショップ名
                  </p>
                  <p className="my-0 text-[14px] text-[#B91C1C] leading-5 font-hiragino">
                    必須
                  </p>
                </div>
                <input
                  className={`w-[460px] h-[16px] appearance-none border-[1px] ${
                    shopNameError ? "border-[#B91C1C]" : "border-lightGray"
                  } border-solid rounded-[12px] py-[16px] pl-[18px] pr-[14px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-tertiary`}
                  id="couponName"
                  type="text"
                  placeholder="ショップ名を入力してください"
                  value={shopName}
                  onChange={handleShopNameChange}
                />
                {shopNameError && (
                  <p className="my-0 text-[14px] text-[#B91C1C] leading-5 font-hiragino">
                    {shopNameErrorMessage}
                  </p>
                )}
              </div>
              <div className="flex justify-end w-[492px] h-[48px] gap-[12px]">
                <button
                  type="button"
                  className="block w-[136px] h-[48px] px-[28px] py-[12px] text-[16px] rounded-[12px] font-medium font-hiragino border-none focus:outline-none bg-white hover:bg-gray-100 hover:cursor-pointer focus:z-10"
                  onClick={() => {
                    setIsEditShopModalOpen(false);
                    resetState();
                  }}
                >
                  キャンセル
                </button>
                <button
                  type="button"
                  className={`block  w-[128px] h-[48px] py-[12px] px-[32px] text-[16px] font-medium font-hiragino border-none rounded-[12px] ${
                    isOkButtonDisabled()
                      ? "bg-[#E4E4E7] cursor-not-allowed text-disableText" // 非活性時のスタイル
                      : "bg-[#001EFB] text-white hover:bg-#334BFC focus:ring-blue-300 hover:cursor-pointer" // 通常時のスタイル
                  }`}
                  onClick={() => {
                    handleEditShopName();
                  }}
                  disabled={isOkButtonDisabled()}
                >
                  更新する
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isQLCodeModalOpen && (
        <div
          id="modal-overlay"
          tabIndex={-1}
          onClick={handleOverlayClick}
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm bg-surfaceOverlay"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative w-[492px] h-[364px]"
          >
            <div className="relative flex flex-col items-start gap-[20px] w-[492px] h-[316px] p-[24px] bg-white border-[1px] border-lightGray border-solid rounded-[16px] shadow-DropShadowLow">
              <h3 className="my-0 text-[18px] font-medium text-[#111827] font-hiragino">
                QRコードを表示
              </h3>
              <div className="flex flex-col gap-[20px] mx-auto">
                <div className="w-150px h-150px">
                  <Canvas
                    text={getQRURL(
                      (serviceID as string) || "",
                      selectedShop?.shopId || ""
                    )}
                    logo={{ src: psImg, options: { width: 30 } }}
                    options={{
                      errorCorrectionLevel: "H",
                      margin: 0,
                      scale: 4,
                      width: 150,
                    }}
                  />
                </div>
                <button
                  type="button"
                  onClick={handleDownloadPNG}
                  className="block w-[152px] h-[36px] py-[8px] px-[12px] rounded-[8px] bg-#F8FAFC font-hiragino border-[1px] border-lightGray border-solid hover:bg-gray-100 hover:cursor-pointer focus:outline-none"
                >
                  <div className="text-14px font-semibold text-#111827 font-hiragino leading-4.5">
                    ダウンロード
                  </div>
                </button>
              </div>
              <div className="flex justify-end w-[492px] h-[48px] gap-[12px]">
                <button
                  type="button"
                  className="block w-[104px] h-[48px] px-[28px] py-[12px] text-[16px] text-#111827 rounded-[12px] font-medium font-hiragino border-none focus:outline-none bg-white  hover:bg-gray-100 hover:cursor-pointer focus:z-10"
                  onClick={() => {
                    resetState();
                    setIsQLCodeModalOpen(false);
                  }}
                >
                  閉じる
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        ref={canvasRef}
        className="w-260px p-20px fixed top-[-1000px] left-[-1000px]"
        style={{ display: isDownloadMode ? "block" : "none" }}
      >
        <div className="w-260px h-260px">
          <Canvas
            text={getQRURL(
              (serviceID as string) || "",
              selectedShop?.shopId || ""
            )}
            logo={{
              src: psImg,
              options: { width: 53 },
            }}
            options={{
              errorCorrectionLevel: "H",
              margin: 0,
              scale: 4,
              width: 260,
            }}
          />
        </div>
        <div className="h-12px"></div>
        <div className="text-20px text-#000 text-center">
          {selectedShop?.name || ""}
        </div>
      </div>
      {IsShopIDAndPasswordConfirmModalOpen && (
        <div
          id="modal-overlay"
          tabIndex={-1}
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm bg-surfaceOverlay"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative w-[492px]"
          >
            <div className="relative flex flex-col items-start gap-[20px] w-[492px] p-[24px] bg-white border-[1px] border-lightGray border-solid rounded-[16px] shadow-DropShadowLow">
              <div>
                <h3 className="my-0 text-[18px] font-medium text-[#111827] font-hiragino">
                  IDとパスワードを生成しました。
                </h3>
                <p className="m-0 mt-2 text-[14px] text-#B91C1C font-hiragino leading-5">
                  以下のログイン用のIDとパスワードは一度しか表示されませんので、必ずメモをお取りください。
                </p>
              </div>
              <div className="flex flex-col items-start gap-[10px] w-[444px] p-[24px] border-[1px] border-lightGray border-solid rounded-[8px] bg-lightGray2">
                <p className="m-0 text-[14px] text-secondary font-hiragino leading-5 break-all">
                  ショップ名：{createShopIDAndPasswordResponse?.shopName}
                </p>
                <p className="m-0 text-[14px] text-secondary font-hiragino leading-5 break-all">
                  ログインID：{createShopIDAndPasswordResponse?.loginId}
                </p>
                <p className="m-0 text-[14px] text-secondary font-hiragino leading-5 break-all">
                  パスワード：{createShopIDAndPasswordResponse?.password}
                </p>
                <p className="m-0 text-[14px] text-secondary font-hiragino leading-5 break-all">
                  ショップ用コンソールURL：
                  {"https://" +
                    createShopIDAndPasswordResponse?.tenantSlug +
                    import.meta.env.VITE_SHOP_CONSOLE_DOMAIN}
                </p>
              </div>
              <div className="flex justify-end w-[492px] h-[48px] gap-[12px]">
                <button
                  type="button"
                  className="block w-[104px] h-[48px] px-[28px] py-[12px] text-[16px] rounded-[12px] font-medium font-hiragino border-none focus:outline-none bg-white  hover:bg-gray-100 hover:cursor-pointer focus:z-10"
                  onClick={() => {
                    setCreateShopIDAndPasswordResponse(null);
                    setShopIDAndPasswordConfirmModalOpen(false);
                  }}
                >
                  閉じる
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showToast && (
        <Toast message={toastMessage} onClose={() => setShowToast(false)} />
      )}
    </>
  );
}

export default ShopListPage;
