import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Timestamp } from "@bufbuild/protobuf";
import { useUser } from "@clerk/clerk-react";

import {
  useMutateAcceptInvitation,
  useQueryGetInvitation,
} from "../../hooks/useQuery";
import { formatDate } from "../../utils/date";
import { useAuth } from "../../hooks/useAuth";

function InvitationPage() {
  const navigate = useNavigate();
  const clerkUser = useUser();
  const { mutateAsync } = useMutateAcceptInvitation();
  const auth = useAuth();
  const params = useParams<{ id: string }>();
  const { data } = useQueryGetInvitation(
    params.id ?? "",
    clerkUser.user?.primaryEmailAddress?.emailAddress ?? ""
  );
  const [expiredAtString, setExpiredAtString] = useState("");
  const [isExpired, setIsExpired] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await mutateAsync({
      invitationId: params.id,
      clerkUserId: clerkUser?.user?.id,
      name:
        clerkUser.user?.lastName && clerkUser.user.firstName
          ? `${clerkUser.user?.lastName} ${clerkUser.user?.firstName}`
          : "",
      email: clerkUser.user?.primaryEmailAddress?.emailAddress ?? "",
    });
    await auth.checkLogin();
    navigate("/staffs");
    location.reload();
  };

  useEffect(() => {
    const date = data?.invitation?.expiresAt;
    if (!date) {
      setExpiredAtString("");
      setIsExpired(true);
      return;
    }
    setExpiredAtString(
      formatDate(
        (data.invitation?.expiresAt as Timestamp)?.toDate() ?? new Date(0),
        "yyyy年MM月dd日"
      )
    );
    setIsExpired(
      (data.invitation?.expiresAt as Timestamp)?.toDate() < new Date()
    );
  }, [data]);

  useEffect(() => {
    // もし既にログイン済みのユーザーが招待リンクを再度踏んだ場合はホームにリダイレクトする。
    if (auth.me) {
      navigate("/");
    }
  });

  if (isSubmitting || auth.loading) {
    return null; // 提出中の場合は何も表示しない
  }

  if (
    !location.pathname.startsWith("/invitation/") ||
    (isExpired &&
      !clerkUser.user?.primaryEmailAddress?.emailAddress?.endsWith(
        "@pocketsign.co.jp"
      )) ||
    (clerkUser.user?.primaryEmailAddress?.emailAddress !=
      data?.invitation?.email &&
      !clerkUser.user?.primaryEmailAddress?.emailAddress?.endsWith(
        "@pocketsign.co.jp"
      ))
  ) {
    return (
      <div className="flex flex-col justify-center items-center h-screen bg-#F3F4F6">
        <div className="flex flex-col w-[512px] h-[300px] rounded-16px border border-[rgba(209,213,219,0.5)] bg-white border-solid border-1px border-lightGray">
          <div className="flex mx-auto mt-[60px]">
            <div className="w-[184.661px] h-[24.167px] leading-27">
              <img src="/invitation_title.svg" alt="Logo" />
            </div>
            <p className="mb-0 ml-[5px] text-secondary text-[rgba(55,65,81,0.8)] font-hiragino text-[26.667px] font-normal leading-[40px]">
              フードロスクーポン
            </p>
          </div>
          <p className="w-[384px] mt-[24px] mx-auto text-gray-900 font-hiragino font-[16px]">
            招待が無効または期限切れです。管理者に問い合わせて招待を受け取ってください。
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col justify-center items-center h-screen bg-#F3F4F6">
        <div className="flex flex-col w-[512px] h-[300px] rounded-16px border border-[rgba(209,213,219,0.5)] bg-white border-solid border-1px border-lightGray">
          <div className="flex mx-auto mt-[20px]">
            <div className="w-[184.661px] h-[24.167px] leading-27">
              <img src="/invitation_title.svg" alt="Logo" />
            </div>
            <p className="mb-0 ml-[5px] text-secondary text-[rgba(55,65,81,0.8)] font-hiragino text-[26.667px] font-normal leading-[40px]">
              フードロスクーポン
            </p>
          </div>
          <p className="mt-[24px] mb-0 mx-auto text-[16px] font-hiragino">
            {data?.invitation?.tenantName}に招待されています。
          </p>
          <button
            type="button"
            onClick={handleSubmit}
            className="h-[48px] w-[384px] mt-[24px] mx-auto p-0 border-0 text-white bg-[#001EFB] hover:bg-#334BFC hover:cursor-pointer font-[16px] rounded-[12px]"
          >
            参加する
          </button>
          <p className="mx-auto text-secondary font-hiragino font-[14px]">
            招待リンクの有効期限 {expiredAtString}
          </p>
        </div>
      </div>
    </>
  );
}

export default InvitationPage;
