import { useCallback, useEffect, useMemo, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import { api } from "../lib/api";

type Staff = {
  id: string;
  name: string;
  email: string;
  serviceID: string;
};

export function useAuth() {
  const [me, setMe] = useState<Staff>();
  const clerkUser = useUser();
  const [loading, setLoading] = useState<boolean>(true);

  // userIdのメモ化
  const userId = useMemo(() => clerkUser.user?.id, [clerkUser.user]);

  // ログイン状態を確認し、ログインされていればMeをセットする
  const checkLogin = useCallback(async () => {
    setLoading(true);
    if (!userId || !clerkUser.isSignedIn || !clerkUser.isLoaded) {
      setMe(undefined);
      setLoading(false);
      return;
    }
    try {
      // staffとして未登録の場合はここで失敗する
      const tenantStaff = await api.getTenantStaffByClerkID({
        clerkUserId: userId,
      });
      const tenantStaffUser = tenantStaff?.tenantStaff;
      if (!tenantStaffUser) {
        throw new Error("tenantStaff not found");
      }
      // staffとして登録済みであった場合、staffの情報を取得してmeにセットする
      console.log(tenantStaff.tenantServiceId);
      setMe({
        id: tenantStaffUser.clerkUserId,
        name: tenantStaffUser.name,
        email: tenantStaffUser.email,
        serviceID: tenantStaff.tenantServiceId,
      });
    } catch (e) {
      setMe(undefined);
    } finally {
      setLoading(false);
    }
  }, [userId, clerkUser.isSignedIn, clerkUser.isLoaded]);

  useEffect(() => {
    checkLogin();
  }, [checkLogin]);

  return {
    me,
    checkLogin,
    loading,
  };
}
