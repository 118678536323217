import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ClerkProvider } from "@clerk/clerk-react";
import { jaJP } from "@clerk/localizations";
import {
  QueryClient,
  QueryCache,
  MutationCache,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ConnectError, Code } from "@connectrpc/connect";
import "virtual:uno.css";
import "normalize.css";
import * as Sentry from "@sentry/react";

import ProtectedLayout from "./components/templates/protectedLayout";
import InvitationPage from "./components/pages/invitationPage";
import ConsoleLayout from "./components/templates/consoleLayout";
import ShopListPage from "./components/pages/shopListPage";
import StaffListPage from "./components/pages/staffListPage";
import AppSettingPage from "./components/pages/appSettingPage";

const router = createBrowserRouter([
  {
    path: "",
    element: <ProtectedLayout />,
    children: [
      {
        path: "",
        element: <ConsoleLayout />,
        children: [
          {
            path: "/",
            element: <ShopListPage />,
          },
          {
            path: "/staffs",
            element: <StaffListPage />,
          },
          {
            path: "/app/setting",
            element: <AppSettingPage />,
          },
        ],
      },
      {
        path: "/invitation/:id",
        element: <InvitationPage />,
      },
    ],
  },
]);

// Import your publishable key
// eslint-disable-next-line
const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

const retry = (failureCount: number, err: unknown) => {
  if (err instanceof ConnectError) {
    if (err.code == Code.PermissionDenied || err.code == Code.Unauthenticated) {
      return false;
    }
  }
  return failureCount < 3;
};

const onError = (err: unknown) => {
  console.error(err);
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({ onError }),
  mutationCache: new MutationCache({ onError }),
  defaultOptions: {
    queries: { retry, staleTime: 60 * 1000 },
    mutations: { retry: false },
  },
});

Sentry.init({
  dsn: "https://acb0dfbbe37080c8d955e217ef2bb591@o4504292631707648.ingest.us.sentry.io/4508415922929664",
  integrations: [],
  environment: import.meta.env.VITE_APP_ENV,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY} localization={jaJP}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ClerkProvider>
  </React.StrictMode>
);
