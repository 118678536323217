import { Outlet, useOutletContext } from "react-router-dom";
import Header from "../organisms/header";
import Sidebar from "../organisms/sidebar";
import { OutletContext } from "./protectedLayout";

function ConsoleLayout() {
  const me = useOutletContext<OutletContext>();
  return (
    <div>
      <Header />
      <div className="h-[52px]"></div>
      <Sidebar />
      <div className="ml-[220px] bg-#F3F4F6 min-h-screen font-hiragino">
        <Outlet context={me satisfies OutletContext} />
      </div>
    </div>
  );
}

export default ConsoleLayout;
