import { UserButton, useClerk, useUser } from "@clerk/clerk-react";
import { useEffect, useMemo } from "react";
import {
  useQueryGetTenant,
  useUpdateTenantStaffNameByClerkUserID,
} from "../../hooks/useQuery";

const Header = () => {
  const clerk = useClerk();
  const { user } = useUser();
  const { data: getTenant } = useQueryGetTenant();
  const updateTenantStaffNameByClerkUserIDMutation =
    useUpdateTenantStaffNameByClerkUserID();
  const { environmentName, environmentColorClass } = useMemo(() => {
    const hostname = window.location.hostname;
    if (hostname === "localhost")
      return {
        environmentName: "ローカル環境",
        environmentColorClass: "bg-gray-400",
      };
    if (hostname.endsWith("mock.pocketsign.dev"))
      return {
        environmentName: "開発環境",
        environmentColorClass: "bg-[#EE793F]",
      };
    if (hostname.endsWith("mock.pocketsign.app"))
      return {
        environmentName: "デモ環境",
        environmentColorClass: "bg-[#059669]",
      };
    return { environmentName: null, environmentColorClass: "" };
  }, []);

  useEffect(() => {
    // Clerk全体の変更イベントにリスナーを追加
    const unsubscribe = clerk.addListener((emission) => {
      updateTenantStaffNameByClerkUserIDMutation
        .mutateAsync({
          clerkUserId: emission.user?.id,
          name: emission.user?.lastName + " " + emission.user?.firstName,
        })
        .then(() => {});
    });

    // コンポーネントがアンマウントされたときにリスナーを解除
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <header className="fixed z-10 top-0 left-0 bg-white border-b-[1px] border-b-lightGray border-b-solid h-[52px] w-full">
        <div className="flex justify-between m-0 p-0">
          <div className="flex px-[20px] py-[12px] gap-[16px]">
            <div className="flex gap-[8px]">
              <h1 className="my-0 text-[16px] font-bold font-hiragino leading-8.5">
                <img src="/header_title.svg" alt="Logo" />
              </h1>
              <h1 className="my-0 text-[16px] font-normal leading-7 font-hiragino text-secondary">
                フードロスクーポン
              </h1>
            </div>
            <div className="w-[1px] h-[28px] bg-lightGray"></div>
            <p className="my-0 text-[14px] text-secondary font-semibold font-hiragino leading-7">
              管理コンソール
            </p>
            <div className="w-[1px] h-[28px] bg-lightGray"></div>
            <p className="my-0 text-[14px] text-secondary font-semibold font-hiragino leading-7">
              組織管理用
            </p>
            <div className="w-[1px] h-[28px] bg-lightGray"></div>
            <p className="my-0 py-[4px] px-[10px] h-[20px] rounded-[5px] bg-lightGray2 text-[14px] text-[#111827] font-hiragino leading-5">
              {getTenant?.tenant?.name ?? "未設定"}
            </p>
            {environmentName && (
              <>
                <div className="w-[1px] h-[28px] bg-lightGray"></div>
                <p className="flex gap-4px my-0 py-[4px] px-[10px] h-[20px] rounded-[5px] bg-lightGray2 text-[14px] text-[#111827] font-hiragino leading-5">
                  <span
                    className={`w-8px h-8px mt-6px rounded-9999px ${environmentColorClass}`}
                  ></span>
                  {environmentName}
                </p>
              </>
            )}
          </div>
          <div className="flex justify-end items-start h-[52px] pr-[24px] gap-[12px] ">
            <p className="m-0 p-[4px] text-[14px] text-[#111827] font-hiragino leading-11">
              {user?.lastName} {user?.firstName}
            </p>
            <div className="pt-[12px] w-[32px] h-[40px]">
              <UserButton />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
