import { useEffect, useState } from "react";
import "dayjs/locale/ja";
import {
  useMutationSendInvitations,
  useQueryGetTenantStaffsByTenantID,
} from "../../hooks/useQuery";
import { useUser } from "@clerk/clerk-react";
import Toast from "../molecules/toast";

function StaffListPage() {
  const [emailList, setEmailList] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // 現在のページ番号
  const limit = 20; // 1ページに表示する件数
  const { data: getTenantStaffsByTenantID, refetch } =
    useQueryGetTenantStaffsByTenantID(currentPage, limit);
  const clerkUser = useUser();
  const [isAddStaffModalOpen, setIsAddStaffModalOpen] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const sendInvitationsMutation = useMutationSendInvitations();

  const handleShopNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setEmailList(event.target.value);
  };

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLElement).id === "modal-overlay") {
      closeAddStaffModal();
    }
  };

  const isOkButtonDisabled = () => {
    return !validateEmails(emailList); // メールリストが無効な場合はtrue
  };

  // メールの正規表現によるバリデーション
  const validateEmails = (emails: string): boolean => {
    const emailArray = emails.split(",").map((email) => email.trim()); // カンマ区切りで分割してトリム
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailArray.every((email) => emailRegex.test(email)); // すべてのメールが有効かチェック
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const closeAddStaffModal = () => {
    setEmailList("");
    setIsAddStaffModalOpen(false);
  };

  const handleSendInvitationsSubmit = () => {
    // emailListを,で分割し、前後の空白を削除
    const emailArray = emailList.split(",").map((email) => email.trim()); // カンマで分割し、前後の空白を削除

    // 不正なメールアドレスがないか確認
    const invalidEmails = emailArray.filter((email) => !validateEmail(email));

    if (invalidEmails.length > 0) {
      // 不正なメールアドレスがある場合、エラーを表示して処理を中断
      console.error(
        `不正なメールアドレスが含まれています: ${invalidEmails.join(", ")}`
      );
      return;
    }

    // 有効なメールアドレスが存在しない場合のチェック
    if (emailArray.length === 0) {
      console.error("有効なメールアドレスがありません");
      return;
    }

    sendInvitationsMutation
      .mutateAsync({
        clerkUserId: clerkUser?.user?.id,
        inviteeEmails: emailArray, // 有効なメールアドレス配列を渡す
      })
      .then(() => {
        setToastMessage("招待メールを送信しました");
        setShowToast(true);
      })
      .catch((err) => {
        console.error("招待の送信に失敗しました", err);
      });
    closeAddStaffModal();
  };

  const nextPage = () => {
    if (
      getTenantStaffsByTenantID &&
      getTenantStaffsByTenantID.total > currentPage * limit
    ) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  // ページを戻す処理
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    refetch(); // currentPageやlimitが変わったらデータを再取得する
  }, [currentPage, limit, refetch]);

  return (
    <>
      <div className="p-[24px]">
        <div className="flex justify-between">
          <h2 className="m-0 text-[20px] font-semibold leading-10 font-hiragino">
            スタッフ一覧
          </h2>
          <button
            type="button"
            onClick={() => setIsAddStaffModalOpen(true)}
            className="block flex gap-[2px] w-[182px] h-[40px] py-[8px] pl-[20px] pr-[24px] rounded-[12px] bg-#001EFB text-[16px] font-medium font-hiragino border-0  text-white  hover:bg-#334BFC hover:cursor-pointer focus:outline-none"
          >
            <div>
              <span className="material-symbols-rounded  text-[24px]">add</span>
            </div>
            <div className="leading-5.8">スタッフを追加</div>
          </button>
        </div>
        <div className="mt-[24px] bg-white rounded-t-[16px]">
          <div className="flex justify-end h-[54px] px-[24px] border-b-[1px] border-b-lightGray border-b-solid">
            <div className="flex gap-[16px] w-[130px]">
              <p className="w-[62px] my-0 text-[#111827] text-[12px] leading-14">
                {currentPage}/
                {Math.ceil((getTenantStaffsByTenantID?.total || 1) / limit)}
                ページ
              </p>
              <div className="flex gap-[4px] pt-[15px]">
                <div onClick={prevPage}>
                  <span className="material-symbols-rounded text-[24px] rounded-999 hover:cursor-pointer">
                    arrow_back
                  </span>
                </div>
                <div onClick={nextPage}>
                  <span className="material-symbols-rounded text-[24px] rounded-999 hover:cursor-pointer">
                    arrow_forward
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex h-[48px] px-[24px] gap-[24px]">
            <p className="w-[200px] text-[14px] font-hiragino text-secondary">
              名前
            </p>
            <p className="w-[216px] text-[14px] font-hiragino text-secondary">
              メールアドレス
            </p>
            <div className="w-8"></div>
          </div>
        </div>
        <div className="bg-white mt-4px rounded-b-[16px]">
          {getTenantStaffsByTenantID?.tenantStaffs
            .filter(
              (tenantStaff) => tenantStaff.clerkUserId == clerkUser?.user?.id
            )
            .map((tenantStaffs, index) => {
              return (
                <div
                  key={index}
                  className={`flex h-[64px] px-[24px] gap-[24px] ${index === getTenantStaffsByTenantID?.tenantStaffs.length - 1 ? "rounded-b-[16px]" : ""} border-b-[1px] border-b-lightGray border-b-solid`}
                >
                  <div className="flex gap-[12px] w-[200px]">
                    <p className="whitespace-nowrap overflow-hidden text-ellipsis text-[14px] leading-10 font-hiragino text-#111827">
                      {tenantStaffs.name}
                    </p>
                    <p className="w-[42px] h-[20px] mt-5.2 py-[2px] px-[6px] text-[14px] text-#047857 font-medium bg-#ECFDF5 border-[1px] border-#34D399 border-solid rounded-[4px] leading-5">
                      あなた
                    </p>
                  </div>
                  <p className="w-[216px] whitespace-nowrap overflow-hidden text-ellipsis text-[14px] leading-10 font-hiragino text-#111827">
                    {tenantStaffs?.email}
                  </p>
                </div>
              );
            })}
          {getTenantStaffsByTenantID?.tenantStaffs
            .filter(
              (tenantStaff) => tenantStaff.clerkUserId !== clerkUser?.user?.id
            )
            .map((tenantStaffs, index) => {
              return (
                <div
                  key={index}
                  className={`flex h-[64px] px-[24px] gap-[24px] ${index === getTenantStaffsByTenantID?.tenantStaffs.length - 1 ? "rounded-b-[16px]" : ""} border-b-[1px] border-b-lightGray border-b-solid`}
                >
                  <p className="w-[200px] whitespace-nowrap overflow-hidden text-ellipsis text-[14px] leading-10 font-hiragino text-#111827">
                    {tenantStaffs.name}
                  </p>
                  <p className="w-[216px] whitespace-nowrap overflow-hidden text-ellipsis text-[14px] leading-10 font-hiragino text-#111827">
                    {tenantStaffs?.email}
                  </p>
                </div>
              );
            })}
        </div>
      </div>
      {isAddStaffModalOpen && (
        <div
          id="modal-overlay"
          tabIndex={-1}
          onClick={handleOverlayClick}
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm bg-surfaceOverlay"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative w-[492px] h-[312px]"
          >
            <div className="relative flex flex-col items-start gap-[20px] w-[492px] h-[312px] p-[24px] bg-white border-[1px] border-lightGray border-solid rounded-[16px] shadow-DropShadowLow">
              <h3 className=" w-[492px] h-[24px] my-0 text-[18px] font-medium text-[#111827] font-hiragino">
                スタッフを追加
              </h3>
              <div className="flex flex-col items-start w-full gap-[4px]">
                <div className="flex gap-[8px]">
                  <p className="w-[110px] h-[24px] my-0 text-[16px] text-[#111827] font-hiragino">
                    メールアドレス
                  </p>
                  <p className="w-[28px] h-[20px] my-0 text-[14px] text-[#B91C1C] leading-5 font-hiragino leading-extraRelaxed">
                    必須
                  </p>
                </div>
                <textarea
                  className="w-[456px] h-[140px] appearance-none border-[1px] border-lightGray border-solid rounded-[12px] py-[16px] px-[18px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-tertiary resize-none"
                  id="couponName"
                  placeholder="メールアドレスをカンマ（,）区切りで入力してください"
                  value={emailList}
                  onChange={handleShopNameChange}
                />
              </div>
              <div className="flex justify-end w-[492px] h-[48px] gap-[12px]">
                <button
                  type="button"
                  className="block w-[136px] h-[48px] px-[28px] py-[12px] text-[16px] rounded-[12px] font-medium font-hiragino border-none focus:outline-none bg-white  hover:bg-gray-100 hover:cursor-pointer focus:z-10"
                  onClick={() => {
                    closeAddStaffModal();
                  }}
                >
                  キャンセル
                </button>
                <button
                  type="button"
                  className={`block  w-[192px] h-[48px] py-[12px] px-[32px] text-[16px] font-medium font-hiragino border-none rounded-[12px] ${
                    isOkButtonDisabled()
                      ? "bg-[#E4E4E7] cursor-not-allowed text-disableText" // 非活性時のスタイル
                      : "bg-[#001EFB] text-white hover:bg-#334BFC focus:ring-blue-300 hover:cursor-pointer" // 通常時のスタイル
                  }`}
                  onClick={() => {
                    handleSendInvitationsSubmit();
                  }}
                  disabled={isOkButtonDisabled()}
                >
                  招待メールを送信
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showToast && (
        <Toast message={toastMessage} onClose={() => setShowToast(false)} />
      )}
    </>
  );
}

export default StaffListPage;
