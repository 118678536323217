import { useState } from "react";
import Toast from "../molecules/toast";
import { useMutationAddMessage } from "../../hooks/useQuery";

function AppSettingPage() {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [message, setMessage] = useState("");
  const addMessageMutation = useMutationAddMessage();

  const handleMessageSubmit = async () => {
    await addMessageMutation.mutateAsync({ message });
    setToastMessage("メッセージを保存しました");
    setShowToast(true);
  };

  return (
    <>
      <div className="p-[24px]">
        <div className="flex justify-between">
          <h2 className="m-0 text-[20px] font-semibold leading-10 font-hiragino">
            アプリ設定
          </h2>
        </div>
        <div className="flex flex-col gap-4px mt-[16px] p-24px bg-white rounded-[16px]">
          <div className="flex gap-8px">
            <div className="text-16px text-#111827 leading-6">
              メッセージ表示
            </div>
            <div className="text-14px text-tertiary leading-6.5">任意</div>
          </div>
          <div className="text-14px text-secondary leading-6">
            フードロスクーポンミニアプリのトップ画面上部に表示したい内容があれば入力してください
          </div>
          <div>
            <input
              className="w-full h-[50px] box-border appearance-none border-[1px] border-lightGray border-solid rounded-[12px] py-[16px] pl-[18px] pr-[14px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-tertiary"
              id="message"
              type="text"
              placeholder="例）現在実証実験中です。"
              value={message}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setMessage(event.target.value)
              }
            />
          </div>
        </div>
        <div className="mt-16px py-16px">
          <button
            type="button"
            onClick={() => handleMessageSubmit()}
            className="block mx-auto gap-[2px] w-[200px] h-[48px] py-[12px] px-[24px] rounded-[12px] bg-#001EFB text-[16px] text-center leading-6 font-semibold font-hiragino border-0  text-white  hover:bg-#334BFC hover:cursor-pointer focus:outline-none"
          >
            保存する
          </button>
        </div>
      </div>
      {showToast && (
        <Toast message={toastMessage} onClose={() => setShowToast(false)} />
      )}
    </>
  );
}

export default AppSettingPage;
